/* eslint-disable react/no-children-prop */
import React from 'react';
import Layout, { LayoutProps } from 'components/layout';
import ReactMarkdown from 'react-markdown';
import { welcome } from '../../docs/welcome';
import config from '../../website-config';

const WelcomeDetails = () => {
    const welcomeText = welcome ? welcome : '';
    return (
        <>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>{config.welcome}</h2>
                        <ol>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Welcome</li>
                        </ol>
                    </div>
                </div>
            </section>
            <section id="teammember-details" className="teammember-details">
                <div className="container">
                    <div className="teammember-description">
                        <ReactMarkdown children={welcomeText} />
                    </div>
                </div>
            </section>
        </>
    );
};
const Welcome = () => {
    const layoutProps: LayoutProps = {
        isHome: false,
        currentPage: 'welcome',
    };
    return (
        <Layout {...layoutProps}>
            <WelcomeDetails />
        </Layout>
    );
};

export default Welcome;
