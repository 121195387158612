import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import Layout, { LayoutProps } from 'components/layout';
import { getMembers } from 'components/sections/teamX';
import { find } from 'lodash';
import { TeamDetails } from 'components/sections/teamDetails';
import { Helmet } from 'react-helmet';
import config from '../../website-config';

const Member = (props: RouteComponentProps<{ memberId: string }>) => {
    const {
        match: {
            params: { memberId },
        },
    } = props;
    const layoutProps: LayoutProps = {
        isHome: false,
        currentPage: 'ourteam',
    };
    const members = getMembers();
    const element = find(members, m => m.id === memberId);
    if (!element) {
        return <Redirect to="/" />;
    }
    const name = element.name;

    return (
        <div>
            <Helmet>
                <title>Calvary Paediatrics - {name}</title>
                <meta name="description" content={config.description} />
                <link rel="canonical" href={`http://calvarypaediatrics.com.au/team/${memberId}`} />
            </Helmet>
            <Layout {...layoutProps}>
                <TeamDetails {...element} />
            </Layout>
        </div>
    );
};

export default Member;
