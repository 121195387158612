/* eslint-disable react/no-children-prop */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MemberInfo, getDetails } from './team';

export const TeamDetails = (member: MemberInfo) => {
    const { id, name, image, bio } = member;

    return (
        <>
            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Team Member Details</h2>
                        <ol>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Team Member Details</li>
                        </ol>
                    </div>
                </div>
            </section>
            <section id="teammember-details" className="teammember-details">
                <div className="container">
                    <div className="teammember-details-container">
                        <div className="teammember-img">
                            {image && <img src={`../${image}`} className="img-fluid" alt="" />}
                        </div>
                        <div className="teammember-details-text">
                            <h2>{name}</h2>
                            {bio && <h4>{bio}</h4>}
                        </div>
                    </div>

                    <div className="teammember-description">
                        <ReactMarkdown children={getDetails(id)} />
                    </div>
                </div>
            </section>
        </>
    );
};
