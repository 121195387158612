/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import teamData from '../../data/team.json';
import { Michael, Paul, Blessy, Tony, Joseph, Yiing } from '../../docs/teamMD';
import { map } from 'lodash';

interface TeamDetailsProps {
    id: string;
    name: string;
    bio: string;
}

export interface MemberInfo {
    id: string;
    name: string;
    image?: string;
    bio?: string;
}

export const getDetails = (id: string) => {
    switch (id) {
        case 'michael':
            return Michael;
            break;
        case 'jo':
            return Jo;
            break;
        case 'paul':
            return Paul;
            break;
        case 'blessy':
            return Blessy;
            break;
        case 'tony':
            return Tony;
            break;
        case 'joseph':
            return Joseph;
            break;
        case 'yiing':
            return Yiing;
            break;
        default:
            break;
    }
    return '';
};

const getTeam = () => {
    const team = teamData as TeamDetailsProps[];
    return team;
};

// const element = find(members, m => m.id === memberId);

const TeamDetail = (props: TeamDetailsProps) => {
    const [isOpen, setIsOpen] = useState(props.id === '1');
    const name = `team${props.id}`;
    const clsNameLink = isOpen ? '' : 'collapsed';
    const clsNameDiv = isOpen ? 'collapse show' : 'collapse';
    const showBio = props.bio !== undefined && props.bio !== '';
    return (
        <li key={props.id}>
            <a data-toggle="collapse" className={clsNameLink} onClick={() => setIsOpen(!isOpen)}>
                <span>{props.name} </span>
                <span className="bio">{showBio && props.bio}</span>
                {isOpen ? <i className="icofont-simple-up"></i> : <i className="icofont-simple-down"></i>}
            </a>
            <div id={name} className={clsNameDiv} data-parent=".faq-list">
                <ReactMarkdown children={getDetails(props.id)} />
            </div>
        </li>
    );
};

export const Team = () => {
    const team = getTeam();
    return (
        <section id="team" className="faq">
            <div className="container">
                <div className="section-title">
                    <h2>Team</h2>
                    <p>Our Experienced Team</p>
                </div>

                <ul className="faq-list">
                    {map(team, t => (
                        <TeamDetail key={t.id} {...t} />
                    ))}
                </ul>
            </div>
        </section>
    );
};
