/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import { useWindowSize } from 'react-use';
import Logo from 'components/icon/logo';
import { Burger } from './burger';
import Hamburger from 'hamburger-react';
import config from '../../website-config';

export interface HeaderProps {
    isHome: boolean;
    currentPage?: string;
}

export interface HeaderDetailsProps {
    currentPage?: string;
}

export interface HeaderMenuProps {
    isMobile: boolean;
    currentPage?: string;
}

export const HeaderButtons = () => {
    const onCallUs = () => {
        window.location.href = `tel:${config.phone}`;
    };
    return (
        <>
            <div className="headerButtons">
                <a href="#" onClick={onCallUs} className="btn-header animated fadeInUp scrollto">
                    {`Call ${config.phoneText}`}
                </a>
                <a href="/appointment" className="btn-header animated fadeInUp scrollto">
                    Request Appointment
                </a>
            </div>
        </>
    );
};

const HeaderMenu = (props: HeaderMenuProps) => {
    const { isMobile, currentPage } = props;
    const onMap = () => {
        const href = `${config.googlemaps}`;
        const a = document.createElement('a');
        a.href = href;
        a.setAttribute('target', '_blank');
        a.click();
    };
    return (
        <>
            <div className={`headerMenu${isMobile ? 'Mobile' : ''}`}>
                <a href="/" className={`${currentPage === 'home' ? 'headerMenuItemActive' : 'headerMenuItem'}`}>
                    Home
                </a>
                <a href="/#team" className={`${currentPage === 'ourteam' ? 'headerMenuItemActive' : 'headerMenuItem'}`}>
                    Our Team
                </a>
                <a href="/#faq" className={`${currentPage === 'faq' ? 'headerMenuItemActive' : 'headerMenuItem'}`}>
                    FAQ
                </a>
                <a
                    href="/#contact"
                    className={`${currentPage === 'contact' ? 'headerMenuItemActive' : 'headerMenuItem'}`}
                >
                    Contact Us
                </a>
            </div>
            <div className="headerMap">
                <a href="#contact" onClick={onMap}>
                    <i className="icofont-google-map"></i>
                </a>
            </div>
            <HeaderButtons />
        </>
    );
};
/*
<a href="/blog" className={`${currentPage === 'blog' ? 'headerMenuItemActive' : 'headerMenuItem'}`}>
Blog
</a>
*/
const HeaderDetails = (props: HeaderDetailsProps) => {
    const { currentPage } = props;
    const { width } = useWindowSize();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    if (width <= 768) {
        return (
            <div className="headerwrapper">
                <div className="logo mr-auto">
                    <Logo />
                    <h1 className="text-light">
                        <a href="index.html">{`${config.title}`}</a>
                    </h1>
                </div>
                <Hamburger toggled={isOpen} toggle={setIsOpen} color={'orange'} />
                <Burger open={isOpen} onOverlayClick={toggle} onEscapePress={toggle}>
                    <HeaderMenu isMobile={true} currentPage={currentPage} />
                </Burger>
            </div>
        );
    }
    return (
        <div className="headerwrapper">
            <div className="logo mr-auto">
                <Logo />
                <h1 className="text-light">
                    <a href="index.html">Calvary Paediatrics</a>
                </h1>
            </div>

            <HeaderMenu isMobile={false} currentPage={currentPage} />
        </div>
    );
};

const Header = (props: HeaderProps): JSX.Element => {
    const { isHome, currentPage } = props;
    const extra = isHome ? 'header-transparent' : '';
    const detailsProps: HeaderDetailsProps = { currentPage };
    if (extra) {
        return (
            <header id="header" className="fixed-top d-flex align-items-center header-transparent">
                <HeaderDetails {...detailsProps} />
            </header>
        );
    }
    return (
        <header id="header" className="fixed-top d-flex align-items-center">
            <HeaderDetails {...detailsProps} />
        </header>
    );
};

export default Header;
