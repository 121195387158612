/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
/* 
const menuBaseStyles: React.CSSProperties = {
    pointerEvents: 'none',
    backgroundColor: '#f2f2f2',
    position: 'absolute',
    zIndex: 100,
    top: '0',
    bottom: '0',
    left: '-300px',
    width: '300px',
    transition: 'all ease 0.5s',
};

const menuOpenStyles: React.CSSProperties = {
    pointerEvents: 'all',
    left: '0',
};

const overlayBaseStyles: React.CSSProperties = {
    opacity: 0,
    pointerEvents: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'absolute',
    zIndex: 100,
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
};

const overlayOpenStyles: React.CSSProperties = {
    opacity: 1,
    transition: 'opacity 0.5s ease',
    pointerEvents: 'all',
    display: 'block',
}; */

export interface PortalProps {
    children: React.ReactNode;
}

export interface BurgerProps {
    children: React.ReactNode;
    open: boolean;
    onOverlayClick: () => void;
    onEscapePress: () => void;
}

export const Portal = (props: PortalProps) => {
    const rootElementRef = useRef<HTMLElement>();

    useEffect(() => {
        if (!rootElementRef.current) {
            rootElementRef.current = document.createElement('div');
        }

        const rootElement = rootElementRef.current;
        document.body.appendChild(rootElement);

        return () => {
            rootElement.remove();
        };
    }, []);

    if (!rootElementRef.current) {
        rootElementRef.current = document.createElement('div');
    }

    return createPortal(props.children, rootElementRef.current);
};

export const Burger = (props: BurgerProps) => {
    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        function handler(event: KeyboardEvent) {
            if (event.which === 27) {
                props.onEscapePress();
            }
        }

        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('keydown', handler);
        };
    });

    React.useEffect(() => {
        if (props.open && modalRef.current) {
            modalRef.current.focus();
        }
    }, [props.open]);

    const tabIndex = props.open ? 0 : -1;

    return (
        <Portal>
            <div
                className={`${props.open ? 'overlayOpenStyles' : 'overlayBaseStyles'}`}
                onClick={props.onOverlayClick}
            />
            <aside
                aria-modal="true"
                role="dialog"
                tabIndex={tabIndex}
                className={`burgerAside ${props.open ? 'menuOpenStyles' : 'menuBaseStyles'}`}
                ref={modalRef}
            >
                {props.children}
            </aside>
        </Portal>
    );
};
