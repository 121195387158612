export interface WebsiteConfig {
    title: string;
    description: string;
    coverImage: string;
    logo: string;
    phone?: string;
    phoneText?: string;
    address?: string;
    email?: string;
    welcome?: string;
    lang: string;
    siteUrl: string;
    brand1: string;
    brand2?: string;
    googlemaps?: string;
    showSubscribe: boolean;
    appointments?: string;
    googleSiteVerification?: string;
    footer?: string;
}

const config: WebsiteConfig = {
    appointments: 'reception@calvarypaediatrics.com.au',
    title: 'Calvary Paediatrics',
    brand1: 'Calvary',
    brand2: 'Paediatrics',
    description:
        'Calvary Paediatrics, a Canberra based paediatric practice based at the Calvary clinic in Belconnen. Our experienced team includes Dr Michael Rosier, Dr Joanne Edwards, Dr Paul Jenkins, Dr Blessy Charles, Dr Tony Lafferty, Dr Sunil Esampalli and Dr Joseph Meredith.',
    coverImage: '/logo.png',
    phone: '0262533011',
    welcome: 'Welcome to Calvary Paediatrics',
    phoneText: '(02) 6253 3011',
    address: 'Suite 2, 40 Mary Potter Cct, Bruce ACT 2617',
    googlemaps:
        'https://www.google.com.au/maps/search/suite+2+calvary+clinic+Mary+Potter+Circuit,+Bruce+ACT/@-35.2532479,149.0892253,17z/data=!3m1!4b1',
    email: 'reception@calvarypaediatrics.com.au',
    logo: '/cp.png',
    lang: 'en',
    siteUrl: 'https://calvarypaediatrics.com.au',
    showSubscribe: false,
    googleSiteVerification: 'GoogleCode',
};

export default config;
