/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import config from '../../website-config';
import { useWindowSize } from 'react-use';

export const ContactUs = () => {
    const onCallUs = () => {
        window.location.href = `tel:${config.phone}`;
    };
    let mapWidth = 600;
    let mapHeight = 450;
    const onMap = () => {
        const href = `${config.googlemaps}`;
        const a = document.createElement('a');
        a.href = href;
        a.setAttribute('target', '_blank');
        a.click();
    };
    const { width } = useWindowSize();
    const isMobile = width <= 768;
    if (isMobile) {
        mapWidth = width - 30;
        mapHeight = (mapWidth * 3) / 4;
    } else {
        const newWidth = (width - 100) / 2;
        const newHeight = (newWidth * 3) / 4;
        mapWidth = newWidth < 600 ? newWidth : mapWidth;
        mapHeight = newWidth < 600 ? newHeight : mapHeight;
    }
    return (
        <section id="contact" className="contact">
            <div className="container">
                <div className="section-title">
                    <h2>Contact</h2>
                    <p>Contact Us</p>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-4">
                        <div className="info">
                            <div className="address">
                                <a href="#contact" onClick={onMap}>
                                    <i className="icofont-google-map"></i>
                                </a>
                                <h4>Location:</h4>
                                <p>{config.address}</p>
                            </div>

                            <div className="email">
                                <a href={`mailto:${config.email}`}>
                                    <i className="icofont-envelope"></i>
                                </a>
                                <h4>Email:</h4>
                                <p>{config.email}</p>
                            </div>

                            <div className="phone">
                                <a href="#contact" onClick={onCallUs}>
                                    <i className="icofont-phone"></i>
                                </a>
                                <h4>Call:</h4>
                                <p>{config.phoneText}</p>
                            </div>

                            <div className="hours">
                                <a href="#hours">
                                    <i className="icofont-clock-time"></i>
                                </a>
                                <h4>Office Hours:</h4>
                                <p>Monday-Thursday 8am - 5pm</p>
                                <p>Friday 8am - 4pm</p>
                                <p>Closed public holidays and weekends</p>
                            </div>

                            <div className="parking">
                                <a href="#parking">
                                    <i className="icofont-car-alt-3"></i>
                                </a>
                                <h4>Parking:</h4>
                                <p>Free parking is available in the multiple story carpark at Calvary Hospital</p>
                                <p>Very limited parking is available in the open air car park</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-8 mt-5 mt-lg-0">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3258.120899116706!2d149.08922526509892!3d-35.25324793029636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1ssuite%202%20calvary%20clinic%20Mary%20Potter%20Circuit%2C%20Bruce%20ACT!5e0!3m2!1sen!2sau!4v1597916027065!5m2!1sen!2sau"
                            width={`${mapWidth}`}
                            height={`${mapHeight}`}
                            frameBorder="0"
                            allowFullScreen={false}
                            aria-hidden="false"
                            tabIndex={0}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};
