import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Home from 'components/pages/home';
import Appointment from 'components/pages/appointment';
import config from './website-config';

import './styles/style.scss';
import Welcome from 'components/pages/welcome';
import Member from 'components/pages/member';

const App = (): JSX.Element => {
    return (
        <BrowserRouter>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Calvary Paediatrics</title>
                <meta name="description" content={config.description} />
                <link rel="canonical" href="http://calvarypaediatrics.com.au" />
            </Helmet>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/appointment" component={Appointment} />
                <Route exact path="/welcome" component={Welcome} />
                <Route exact path="/team/:memberId" component={Member} />
            </Switch>
        </BrowserRouter>
    );
};
/*
                
                
import Blog from 'components/pages/blog';
import Article from 'components/pages/article';
 <Route exact path="/blog/:page?" component={Blog} />
<Route exact path="/article/:blogId" component={Article} />
*/
/* 
<main id="main">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                </p>
                <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
                    Learn React
                </a>
            </header>
        </main>
<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
      </header>
    </div> */
export default App;
