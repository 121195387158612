/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Waves from '../images/waves';

/* <a href="/welcome" className="btn-get-started animated fadeInUp scrollto">
                Read More
            </a> */
export const Hero = () => {
    return (
        <section id="hero" className="d-flex flex-column justify-content-end align-items-center">
            <div id="heroWrapper" className="carousel-container">
                <h2 className="animated fadeInDown">
                    Welcome to <span>Calvary Paediatrics</span>
                </h2>
            </div>
            <p className="animated fadeInUp">
                Calvary Paediatrics provides the services of General Paediatricians, a Paediatric Endocrinologist and a
                Paediatriac Gastroenterologist and Hepatologist.
            </p>
            <p className="animated fadeInUp">
                Our goal is to provide comprehensive services to the children of Canberra and surrounding districts. We
                see children from birth through to 16-18 years of age (depending on the condition) and our team each
                have their own area of interest and expertise.
            </p>
            <Waves />
        </section>
    );
};
