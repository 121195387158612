import React from 'react';
import { useWindowSize } from 'react-use';
import config from '../../website-config';
import { HeaderButtons } from 'components/header';

const FooterButtons = () => {
    const onMap = () => {
        const href = `${config.googlemaps}`;
        const a = document.createElement('a');
        a.href = href;
        a.setAttribute('target', '_blank');
        a.click();
    };
    return (
        <>
            <div className="headerMap">
                <i className="icofont-google-map"></i>
                <a href="#contact" onClick={onMap} className="btn-header animated fadeInUp scrollto">
                    Our location
                </a>
            </div>
            <HeaderButtons />
        </>
    );
};

const Footer = (): JSX.Element => {
    const { width } = useWindowSize();
    const isMobile = width <= 768;
    const classToUse = isMobile ? 'footerWrapperMobile' : 'footerWrapper';
    return (
        <footer id="footer">
            <div className={classToUse}>
                {isMobile && <FooterButtons />}
                <div>
                    <div className="copyright">
                        &copy; Copyright{' '}
                        <strong>
                            <span>Calvary Paediatrics</span>
                        </strong>
                        . All Rights Reserved
                    </div>
                </div>
                {!isMobile && <FooterButtons />}
            </div>
        </footer>
    );
};

export default Footer;
