/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Layout, { LayoutProps } from 'components/layout';
import { Hero } from 'components/sections/hero';
import { Team } from 'components/sections/team';
import { ContactUs } from 'components/sections/contactus';
import { Faq } from 'components/sections/faq';
import { useInView } from 'react-intersection-observer';

export interface HomePageProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
}

const Home = (props: HomePageProps): JSX.Element => {
    const { children } = props;

    // const [isTeamInViewport, teamTargetRef, viewportRefToChainTeam] = useIsInViewport({
    //     threshold: 50,
    // });
    // const [isFaqInViewport, faqTargetRef, viewportRefToChainFaq] = useIsInViewport({
    //     viewport: viewportRefToChainTeam,
    //     threshold: 50,
    // });
    // const [isContactInViewport, contactTargetRef, viewportRef] = useIsInViewport({
    //     viewport: viewportRefToChainFaq,
    //     threshold: 50,
    // });
    const [teamTargetRef, isTeamInViewport] = useInView({
        threshold: 0.5,
    });
    const [faqTargetRef, isFaqInViewport] = useInView({
        threshold: 0.5,
    });
    const [contactTargetRef, isContactInViewport] = useInView({
        threshold: 0.5,
    });

    const check = (value: boolean | null) => value !== null && value;

    const currentPage = check(isContactInViewport)
        ? 'contact'
        : check(isFaqInViewport)
        ? 'faq'
        : check(isTeamInViewport)
        ? 'ourteam'
        : 'home';
    const layoutProps: LayoutProps = {
        isHome: false,
        currentPage,
        children,
    };
    return (
        <div>
            <Layout {...layoutProps}>
                <Hero />
                <div className="homealertwrapper">
                    <div className="alert alert-secondary" role="alert">
                        <div className="homealert">
                            <p>
                                The team at Calvary Paediatrics are excited to announce that Dr Yiing Yiing Goh has
                                joined our team and is accepting new patients.
                            </p>
                            <p>She is a general paediatrician with a special interest in diabetes and Spina bifida.</p>
                        </div>
                    </div>
                </div>
                <div className="homealertwrapper">
                    <div className="alert alert-secondary" role="alert">
                        <div className="homealert">
                            <p>Unfortunately, Dr Rosier&apos;s books are closed to all new patients.</p>
                            <p>Please contact our office to check availability.</p>
                        </div>
                    </div>
                </div>
                <div ref={teamTargetRef}>
                    <Team />
                </div>
                <div ref={faqTargetRef}>
                    <Faq />
                </div>
                <div ref={contactTargetRef}>
                    <ContactUs />
                </div>
            </Layout>
        </div>
    );
};

export default Home;
