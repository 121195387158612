export const Michael = `
Dr Michael Rosier is a General Paediatrician who has practiced in Canberra for over 30 years. He obtained his medical degree at the University of NSW and then completed his paediatric specialist training at the Sydney Children's Hospital (then the Prince of Wales Children's Hospital). He was awarded Fellowship of the Royal Australasian College of Physicians in 1990.

Dr Rosier has had appointments at The Canberra Hospital until recently and continues to provide on-call cover to the Maternity Unit at Calvary Bruce Hospital.

His private rooms are located at the Calvary Specialist Centre on the Calvary Bruce Hospital site.

Dr Rosier sees children from birth until 18 years of age with all paediatric medical issues.

He has an interest in paediatric neurology and epilepsy in particular.

Appointments are available on Mondays, Tuesdays and Wednesdays.`;

export const Paul = `
Dr Paul Jenkins graduated MBBS from UNSW in 1976 and began Paediatrics in 1978 at St Margaret's Hospital. He then completed five years at Royal Alexandra Hospital for Children Sydney, with one year as Fellow at Queen Elizabeth Hospital London working in Gastroenterology and Immunology . He was awarded FRACP in 1984. Following his return to Australia he moved to Canberra in 1984 as a private Paediatrician and helped develop the Neonatal lntensive Care Unit , firstly at Royal Canberra Hospital then at Canberra Hospital. He worked as a private Paediatrician for twenty-four years.

At the commencement of the ANU Medical School in 2005 he moved to a role as Staff General Paediatrician at The Canberra Hospital with an interest in Gastroenterology, Nutrition and Teaching. He was Director of Paediatric Education for the ANU Medical School for 6 years.

He joined Dr Michael Rosier and Dr Joanne Edwards at Calvary Paediatrics in 2018 and has an ongoing interest in Paediatric Gastroenterology, childhood abdominal pain as well as General Paediatrics.
`;

export const Blessy = `Dr Blessy Charles is a general paediatrician with a special interest in paediatric allergy.`;

export const Tony = `Dr Tony Lafferty is a Paediatric Endocrinologist and former Clinical Director of Paediatrics. Tony graduated from the University of Otago Medical School in New Zealand, then moved to Melbourne to train in Paediatrics. He then trained in Paediatric Endocrinology and Diabetes at the Royal Children's Hospital Melbourne. After spending 2 years at the National Institute of Child Health and Human Development at the NIH in Maryland, US, he returned to Melbourne in 2000 as a visiting NIH Fellow and Senior Lecturer at Monash University.

In 2001, he accepted a position at Princess Margaret Hospital for Children in Perth where he worked for 4 years before moving to Canberra in 2005. Since arriving in Canberra, Tony helped to establish a Paediatric Endocrinology and Diabetes service and has continued his clinical and research interests in obesity, children's diabetes and growth disorders, and all aspects of paediatric endocrinology.

He will accept referrals for all paediatric endocrine conditions except for type 1 diabetes and gender dysphoria.`;

export const Joseph = `Paediatric Gastroenterology and Hepatology

Paediatric gastroenterologist and hepatologist who has spent the last 3 years (2019-2022) in the United Kingdom - working as a consultant at the Royal Hospital for Children and Young People, Edinburgh and the Royal Hospital for Children, Glasgow. 

This was concurrent with a research fellowship for the University of Edinburgh focusing on Paediatric Inflammatory Bowel Disease (IBD) and nutrition. 

I completed fellowship training in paediatric gastroenterology, hepatology and nutrition (PGHAN) at The Children’s Hospital at Westmead, Sydney, Australia – having attained specialty fellowship in the field in 2019. I am an accredited endoscopist for both diagnostic and procedural gastroscopies/colonoscopies in Australia and the UK and am an accredited trainer for paediatric endoscopy.

I have a particular interest in managing paediatric inflammatory bowel and liver diseases but also manage a range of conditions including, but not limited to; coeliac disease, eosinophilic oesophagitis, complex nutritional conditions including short gut syndrome, gastroesophageal reflux disease and helicobacter pylori disease. 

I have a proven capacity to deliver quality outcomes in clinical medicine, education and research simultaneous to a demanding daily clinical workload. (Examples of my research can be found at [https://orcid.org/0000-0001-5206-6720](https://orcid.org/0000-0001-5206-6720) Loop profile: 961866). I recognise the value in combining all 3 domains in regular practice to improve patient outcomes in paediatric gastroenterology and look forward to providing regular CME sessions in the field of PGHAN for primary care physicians in the ACT.`;

export const Yiing = `Dr Yiing Yiing Goh is a general paediatrician who has moved from New Zealand in April 2024. She attended the University of Glasgow in Scotland and completed the MBChB degree in 2005. She started her Paediatric Training in Southland Hospital, NZ in 2007 and moved to Canberra in 2008. She underwent her paediatric training in both Australia and New Zealand. She was awarded Fellowship of the Royal Australasian College of Physicians in 2016.

Dr Goh was appointed as a Research Paediatrician working on RSV vaccine Norovax for pregnant women during 2017- 2018. She has been working as a Specialist Paediatrician from 2016 in New Zealand. Other than working in a General Paediatric clinic, she has developed a special interest in diabetes and Spina bifida during her appointment at the public hospital in New Zealand. She is also a Senior Lecturer for the University of Auckland. She is accepting referrals for all Paediatric related issues including neurodevelopmental disorders.

Dr Goh is fluent in English, Mandarin, Malay, Cantonese, Hokkien and Teo Chew. She has also started to learn Portuguese.`;
