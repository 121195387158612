import React from 'react';
import Footer from './footer';
import Header, { HeaderProps } from './header';

export interface LayoutProps {
    currentPage?: string;
    isHome?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any;
}

const Layout = (props: LayoutProps): JSX.Element => {
    const { children, isHome, currentPage } = props;
    const home = isHome !== undefined ? isHome : false;
    const headerProps: HeaderProps = {
        isHome: home,
        currentPage,
    };
    return (
        <div className="siteLayout">
            <Header {...headerProps} />
            <main id="main" className="siteMain">
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
