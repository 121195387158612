/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import faqData from '../../data/faq.json';
import { map } from 'lodash';

interface FaqDetailsProps {
    id: string;
    question: string;
    answer: string;
}

const getFaqs = () => {
    const faqs = faqData as FaqDetailsProps[];
    return faqs;
};

const FaqDetail = (props: FaqDetailsProps) => {
    const [isOpen, setIsOpen] = useState(props.id === '1');
    const name = `faq${props.id}`;
    const clsNameLink = isOpen ? '' : 'collapsed';
    const clsNameDiv = isOpen ? 'collapse show' : 'collapse';
    const whatToBring = `
 * referral
 * Child health record (blue book)
 * any relevant investigation results
 * correspondence i.e. relevant assessments
 * school reports
`;
    const answer = props.id === '5' ? whatToBring : props.answer;
    return (
        <li>
            <a data-toggle="collapse" className={clsNameLink} onClick={() => setIsOpen(!isOpen)}>
                {props.question}{' '}
                {isOpen ? <i className="icofont-simple-up"></i> : <i className="icofont-simple-down"></i>}
            </a>
            <div id={name} className={clsNameDiv} data-parent=".faq-list">
                <ReactMarkdown children={answer} />
            </div>
        </li>
    );
};

export const Faq = () => {
    const faqs = getFaqs();
    return (
        <section id="faq" className="faq">
            <div className="container">
                <div className="section-title">
                    <h2>F.A.Q</h2>
                    <p>Frequently Asked Questions</p>
                </div>

                <ul className="faq-list">
                    {map(faqs, faq => (
                        <FaqDetail key={faq.id} {...faq} />
                    ))}
                </ul>
            </div>
        </section>
    );
};
