import React from 'react';
import { map } from 'lodash';
import members from '../../data/team.json';

export interface MemberInfo {
    id: string;
    name: string;
    image?: string;
    bio?: string;
}

export const getMembers = () => {
    const memberInfo = members as MemberInfo[];
    return memberInfo;
};

const MemberDetails = (info: MemberInfo) => {
    const { id, name, image, bio } = info;
    return (
        <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
            <div className="member">
                <div className="member-img">{image && <img src={image} className="img-fluid" alt="" />}</div>
                <div className="member-info">
                    <h4>{name}</h4>
                    {bio && <span>{bio}</span>}
                </div>
                <div>
                    <a href={`/team/${id}`} className="btn-header animated fadeInUp scrollto">
                        More info
                    </a>
                </div>
            </div>
        </div>
    );
};

export const TeamX = () => {
    const members = getMembers();
    const elements = map(members, m => {
        return <MemberDetails {...m} />;
    });
    return (
        <section id="team" className="team">
            <div className="container">
                <div className="section-title">
                    <h2>Team</h2>
                    <p>Our Experienced Team</p>
                </div>
                <div className="row">{elements}</div>
            </div>
        </section>
    );
};
