/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Layout, { LayoutProps } from 'components/layout';
import { useFormik, FormikErrors } from 'formik';
import config from '../../website-config';
import { split, forEach, replace } from 'lodash';
import DOMPurify from 'isomorphic-dompurify';
import { Helmet } from 'react-helmet';
import { AppointmentDetails, EmailDetails } from '../../models/email';

const Appointment = () => {
    const [status, setStatus] = useState('');

    const layoutProps: LayoutProps = {
        isHome: false,
        currentPage: 'appointment',
    };
    const initial: AppointmentDetails = {
        patientName: '',
        contactName: '',
        phone: '',
        email: '',
        description: '',
    };

    const formik = useFormik<AppointmentDetails>({
        initialValues: initial,
        onSubmit: values => {
            console.log(values);
            setStatus('submitted');
        },
        validate: values => {
            const errors: FormikErrors<AppointmentDetails> = {};
            const regexp = new RegExp(`(^(?!04|614)[0-9]{10,15}$)|(^(04|614)[0-9]{8}$)`, 'i');
            if (!values.patientName) {
                errors.patientName = 'Required';
            }
            if (!values.contactName) {
                errors.contactName = 'Required';
            }
            if (!values.description) {
                errors.description = 'Required';
            }
            if (!values.email) {
                errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.phone) {
                errors.phone = 'Required';
            } else if (!regexp.test(values.phone)) {
                errors.phone = 'Invalid phone number';
            }

            return errors;
        },
    });

    const { errors, touched, handleChange, values, resetForm } = formik;

    const sendEmail = async () => {
        const cleanDescription = DOMPurify.sanitize(values.description);
        const cleanPatientName = DOMPurify.sanitize(values.patientName);
        const cleanContactName = DOMPurify.sanitize(values.contactName);
        const cleanPhone = DOMPurify.sanitize(values.phone);
        const cleanEmail = DOMPurify.sanitize(values.email);

        const text = `Name: ${cleanPatientName}, Email: ${cleanEmail}, phone: ${cleanPhone}, Message: ${replace(
            cleanDescription,
            /[\n\r]/g,
            '',
        )}`;
        const header = `Appointment request for - ${cleanPatientName}`;
        const lines = split(cleanDescription, /[\n\r]/g);
        let description = `<p>Contact: ${cleanContactName} email: ${cleanEmail} phone: ${cleanPhone}</p><p />`;
        forEach(lines, (l: string) => {
            description = `${description}<p>${l}</p>`;
        });
        const html = `<h3>${header}</h3>${description}`;
        const url = 'api/sendemail';

        const details: EmailDetails = {
            subject: header,
            body: html,
            type: 'appointment',
            text,
        };

        const rawBody = JSON.stringify(details);

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: rawBody,
        })
            .then(responseJson => {
                const response = responseJson;
                console.log('sent', response);
            })
            .catch(error => {
                console.log('error', error);
                setStatus('error');
            });
    };

    useEffect(() => {
        if (status === 'submitted') {
            setStatus('loading');
        }
        if (status === 'loading') {
            sendEmail();
            setStatus('sent');
            resetForm();
        }
    }, [status]);

    const isLoading = status === 'loading';
    const isSent = status === 'sent';
    const isError = status === 'error';

    return (
        <div>
            <Helmet>
                <title>Calvary Paediatrics - Request Appointment</title>
                <meta name="description" content={config.description} />
                <link rel="canonical" href={`http://calvarypaediatrics.com.au/appointment`} />
            </Helmet>
            <Layout {...layoutProps}>
                <section id="contact" className="contact">
                    <div className="container">
                        <div className="section-title">
                            <h2>Appointment</h2>
                            <p>Request an Appointment</p>
                        </div>
                        <div className="alert alert-primary" role="alert">
                            Waiting times may apply
                        </div>
                        <div className="col-lg-12 mt-5 mt-lg-0">
                            <form onSubmit={formik.handleSubmit} className="php-email-form">
                                <div className="form-group">
                                    <label htmlFor="patientName">Patient Name</label>
                                    <input
                                        type="text"
                                        name="patientName"
                                        className="form-control"
                                        id="patientName"
                                        placeholder="Enter name of patient"
                                        onChange={handleChange}
                                        value={values.patientName}
                                    />
                                    <div className="validate">
                                        {errors.patientName && touched.patientName ? (
                                            <div>{errors.patientName}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="contactName">Contact Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="contactName"
                                        id="contactName"
                                        placeholder="Enter name of contact person"
                                        onChange={handleChange}
                                        value={formik.values.contactName}
                                    />
                                    <div className="validate">
                                        {errors.contactName && touched.contactName ? (
                                            <div>{errors.contactName}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="email">Contact Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            placeholder="Enter valid email address for contact"
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />
                                        <div className="validate">
                                            {errors.email && touched.email ? <div>{errors.email}</div> : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="contactName">Contact Phone</label>
                                        <input
                                            type="phone"
                                            className="form-control"
                                            name="phone"
                                            id="phone"
                                            placeholder="Enter valid phone number for contact"
                                            onChange={formik.handleChange}
                                            value={formik.values.phone}
                                        />
                                        <div className="validate">
                                            {errors.phone && touched.phone ? <div>{errors.phone}</div> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Description</label>
                                    <textarea
                                        className="form-control"
                                        name="description"
                                        id="description"
                                        rows={5}
                                        placeholder="Please provide a brief description for this request for appointment"
                                        onChange={handleChange}
                                        value={values.description}
                                    ></textarea>
                                    <div className="validate">
                                        {errors.description && touched.description ? (
                                            <div>{errors.description}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-3">
                                    {isLoading && <div className="loading">Loading</div>}
                                    {isSent && (
                                        <div className="sent-message">Your request has been sent. Thank you!</div>
                                    )}
                                    {isError && (
                                        <div className="error-message">
                                            Problem sending your request, please try again.
                                        </div>
                                    )}
                                </div>
                                <div className="text-center">
                                    <button type="submit">Request Appointment</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </Layout>
        </div>
    );
};

export default Appointment;
